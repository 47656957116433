// uncomment the line below if you want to activate why-did-you-render
// import './wdyr';
import 'core-js';
import 'regenerator-runtime/runtime';
// @ts-expect-error [TS migration] Was not detected by flow
import amplitude from 'amplitude-js';

import * as React from 'react';

import store from 'store/store';
import { getClientBind } from 'store/APIClient';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';
import { LicenseManager } from 'ag-grid-enterprise';
import 'styles/main.scss';
import './i18n';
import { router } from 'store/router';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import {
    AG_GRID_KEY,
    AMPLITUDE_KEY,
    FLAVOUR,
    IS_PRESENTATION,
    IS_PROD,
    IS_STAGING,
    SENTRY_DSN,
    VERSION,
} from 'env';

LicenseManager.setLicenseKey(AG_GRID_KEY);

// Setup Sentry for exception monitoring, Hotjar etc
if (IS_STAGING || IS_PROD || IS_PRESENTATION) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: IS_PROD ? 'production' : 'staging',
        release: VERSION,
    });
    Sentry.setTag('flavour', FLAVOUR);

    const isE2eTest = window.location.host.includes('e2e-test-app');

    amplitude.getInstance().init(AMPLITUDE_KEY);

    // Do not load datadog-RUM in e2e test
    if (!isE2eTest) {
        const bind = getClientBind();

        datadogRum.init({
            applicationId: 'd568b27d-573b-4af3-a107-57f7b55a4446',
            clientToken: 'pubd605e6807fc7051b6ac75c77bb672d06',
            site: 'datadoghq.com',
            service: 'vision',
            env: IS_PROD ? 'production' : 'staging',
            sampleRate: 100,
            trackInteractions: true,
            trackViewsManually: true,
            allowedTracingOrigins: [/https:\/\/.*\.manty\.eu/],
        });

        datadogRum.setGlobalContextProperty('bind', { bind });
        datadogRum.setGlobalContextProperty('flavour', FLAVOUR);
    }
}

const MOUNT_NODE = document.getElementById('root');
if (!MOUNT_NODE) {
    throw new Error('Root element not found');
}

createRoot(MOUNT_NODE).render(
    <StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </StrictMode>,
);
